import * as React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from 'styled-components'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const bgColor = '#eeefed'

  return (
    <div style={{ backgroundColor: bgColor }}>
      <Layout
        header={t("header", { returnObjects: true })}
        footer={t("footer", { returnObjects: true })}
        bgColor={bgColor}
      >
        <Styled>
          <h1>TERMOS E CONDIÇÕES LARD DARTE</h1>
          <br />
          <p>A Lar D'arte - Carpintaria e Móveis de Cozinha Lda, com sede na Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire, com o NIPC 502942762, é detentora do presente
            website. A utilização do www.lardarte.pt reconhece os termos e condições gerais abaixo enumerados e descritos. A sua aceitação é absolutamente indispensável à utilização
            dos serviços e informações aqui prestados. Caso não concorde com os mesmos, deverá cessar imediatamente a utilização deste website. www.lardarte.pt é um serviço online
            com o objetivo de distribuir informação sobre os serviços prestados pela Lar Darte, bem como divulgar e promover as respetivas características e condições dos mesmos.
            <br />À Lar Darte reserva-se o direito de, a qualquer momento, sem necessidade de aviso prévio e com efeitos imediatos, alterar, adicionar, atualizar, eliminar, parcial
            ou totalmente, os presentes Termos e Condições de Utilização e Política de Privacidade. Garantindo, em qualquer circunstância, a qualidade, segurança, legalidade,
            veracidade e exatidão das informações dos respetivos serviços no website publicados. O utilizador deve consultar periodicamente a página destes Termos e Condições
            de utilização e Política de Privacidade para confirmar se foram efetuadas quaisquer atualizações ou alterações. Caso não concorde com alguma destas não deve utilizar
            o presente website. </p>
          <br />
          <p><b>Utilização do Website</b>
            <br />
            Os utilizadores que visitam o website podem aceder e ler todo o conteúdo contido no mesmo, sem necessidade de registar dados ou informações pessoais. No entanto,
            apenas os utilizadores registados ou com a submissão de formulários preenchidos para pedido de orçamento ou de contacto serão contactados e informados sobre o serviço
            através do endereço eletrónico ou telefone previamente submetidos em formulários próprios. Receberão atualizações e informações sobre os serviços disponíveis na Lar
            Darte, no caso de terem previamente aceite no respetivo registo ou formulário submetido, comunicação para esse efeito.</p><br /><p>Este website, ou qualquer um dos
              nossos serviços, não deverá ser utilizado:</p>
          <br />
          <ul>
            <li>Por qualquer razão ou finalidade que seja ilegal, difamatória, ofensiva, prejudicial, abusiva, invasiva
              da privacidade de outrem ou censurável;</li>
            <li>De forma que possa prejudicar ou interromper este website ou qualquer um dos nossos serviços, ou a utilização
              pacífica por parte de outros utilizadores;</li>
            <li>De forma que possa prejudicar ou interromper este website ou qualquer um dos nossos serviços, ou a utilização
              pacífica por parte de outros utilizadores;</li>
            <li>De forma que possa ofender outrem ou prejudicar a Lar Darte, designadamente, enviar ou disponibilizar
              qualquer conteúdo ilegal, falso, enganoso, ameaçador, maldoso, abusivo, difamatório, injurioso, invasivo da privacidade, prejudicial ou ainda que possa
              afetar negativamente a imagem ou atividade da Lar Darte;</li>
            <li>Para promover qualquer tipo de atividade comercial, ou enviar ou disponibilizar informação
              ou conteúdos que pertencem a terceiros e que não tem o direito de utilizar, como, por exemplo, conteúdos protegidos por direitos de autor de terceiros ou
              conteúdos que contenham dados pessoais de terceiros.</li>
          </ul>
          <br />
          <p>Em geral, o utilizador deve utilizar o website de forma responsável, prudente e cuidadosa, não devendo perturbar ou danificar a continuidade ou integridade do mesmo.
            <br />
            A Lar Darte não é responsável por quaisquer danos diretos, indiretos e ou negligentes decorrente do uso da informação contida neste website ou do próprio website bem
            como da conduta prejudicial ou perturbadora do utilizador.</p><br /><p><b>Informações e conteúdos deste website</b></p><br /><p>Todos os conteúdos e informações publicados
              no presente website são da responsabilidade da Lar Darte que zela pela veracidade e transparência dos mesmos. Esforçando-se para oferecer informações úteis, atualizadas
              e ajustadas à realidade. No entanto, a mesma pode não estar correta ou completamente atualizada. Neste sentido, não nos podemos responsabilizar por quaisquer ações
              tomadas como resultado de ou com base nas informações contidas ou fornecidas por este website, seja numa ação de contrato, negligência ou outra ação ilícita. Através
              do lardarte@lardarte.pt poderá sempre enviar as suas sugestões ou erratas.</p><br /><p>À Lar Darte está reservado o direito de mover, alterar, excluir o website, ou
                conteúdo do mesmo (incluindo o conteúdo de utilizadores registados, se o caso), a qualquer momento e por qualquer motivo. Deve, portanto, manter uma cópia de qualquer
                conteúdo que enviar para o website, que deseje manter. Depois de ter carregado o conteúdo, pode optar por excluir o conteúdo a qualquer momento.</p>
          <br />
          <p>Caso deseje reportar as suas preocupações relativas a qualquer conteúdo disponível neste site, por favor, envie um e-mail diretamente para lardarte@lardarte.pt</p>
          <br /><p><b>Registo de dados</b></p><br /><p>É permitido o registo de dados de todos os utilizadores interessados em receber informações ou pedir um orçamento.</p>
          <br /><p>O registo ou preenchimento de formulários são gratuitos.<br />Os dados pessoais de cada utilizador serão mantidos enquanto a subscrição e/ou pedidos do
            utilizador estiverem ativos, com o objetivo de melhorar os serviços oferecidos, pelo que após a sua caducidade serão despersonalizados e não identificáveis.
            <br />É-lhe permitido questionar os dados e poderá solicitar a eliminação dos dados, retificação, atualização ou completar os mesmos. Estes pedidos devem ser e
            ncaminhados para lardarte@lardarte.pt<br />Sugerimos a consulta da Política de Privacidade do presente website.</p><br /><p><b>Utilização de Hyperlinks</b></p>
          <br /><p>O conteúdo deste website pode incluir Hyperlinks (hiperligações) para outros websites que não são operados pela Lar Darte. A Lar Darte não é responsável
            pelo conteúdo de qualquer outro website, incluindo aqueles que dêem acesso ao presente ou cujo acesso tenha sido conseguido através do nosso website.</p><br />
          <p>A Lar Darte não endossa quaisquer webites vinculados ou os produtos/serviços que são fornecidos nesses websites. Os conteúdos nos wesites vinculados podem
            mudar ao longo do tempo. Recomenda-se a verificação dos termos e políticas dos websites vinculados.</p><br /><p>Ao fornecer neste website um hyperlink para
              um website externo, a Lar Darte fá-lo na boa-fé de que este contenha ou possa conter informações relevantes adicionais às apresentadas no seu próprio website.
              A existência desse hyperlink não implica nem pressupõe a sua revisão e aprovação por parte da Lar Darte - em determinadas instâncias, um hyperlink poderá
              levá-lo a aceder a um website que apresente informação contraditória à apresentada no nosso website ou a outro pertencente à Lar Darte.</p>
          <br />
          <p><b>A Nossa Responsabilidade e Contactos</b></p>
          <br />
          <p>A Lar Darte não é responsável, por perdas ou danos diretos, indiretos ou específicos ou quaisquer outras consequências provenientes do acesso ou utilização
            deste website, nem de qualquer informação no mesmo contida. Não obstante, estamos disponíveis para discutir com o utilizador as implicações de qualquer questão
            que venha a surgir, devendo contactar-nos através do nosso site em lardarte@lardarte.pt (contactos), através da morada Zona Industrial da Ouvida, Lote 5, 3600-476
            Castro Daire, do nosso telefone +351 232 388 059 /060, ou através do endereço de e-mail lardarte@lardarte.pt.</p><br /><p>Ao aceder à nossa área de contacto, o
              utilizador estará a aceitar que a informação que nos presta, através desse contacto, passará a ser detida pela Lar Darte, que a poderá utilizar nos termos que
              entender mais adequados, não havendo lugar, em situação alguma, a qualquer direito ou pagamento a seu favor por essa utilização.<br />Até ao limite máximo permitido
            por lei, todas as garantias, explícitas ou implícitas, encargos ou avais, representações e outros termos e condições de qualquer tipo relacionada com este website
            ou matéria não contida nestes Termos e Condições estão expressamente excluídas.</p><br /><p>Nada nestes Termos de Utilização exclui, restringe ou modifica qualquer
              direito ou recurso, ou quaisquer garantias, ou outro termo ou condição, implícita ou impostas por qualquer legislaçãque não possam ser legalmente excluídas ou
              limitadas. Isso pode incluir a legislação referentaos Direitos do Consumidor, em território Português, que contém garantias que protegem oConsumidores de bens
              e serviços em determinadas circunstâncias.</p><br /><p>A responsabilidade de uma parte para com outra parte é reduzida na medida em que os atos oomissões da
                outra parte, ou aqueles de uma terceira parte, contribuem para ou causam taresponsabilidade.<br />Apesar de desenvolvermos as correspondentes ações para manter
            este website livre de víruinformáticos ou outra codificação danosa, não podemos garantir que este seja o caso. Deveráportanto, utilizar um software de segurança,
            atualizado a todo o tempo, para proteger o secomputador de tais softwares maliciosos.</p><br /><p>Apesar de desenvolvermos todos os esforços comerciais razoáveis
              para manter este website disponível, não garantimos que o mesmo esteja acessível ou disponível numa base temporal constante ou que o acesso não seja interrompido
              ou isento de erros.</p>
          <br />
          <p>A Lar Darte é a entidade responsável pela recolha e tratamento de dados pessoais. Respeita a privacidade de todos os
            utilizadores e compromete-se em proteger todas as informações submetidas por cada utilizador em conformidade com a legislação nacional e comunitária em vigor.
            <br />
            Todos os utilizadores podem navegar neste website sem a obrigatoriedade de efetuar ou submeter qualquer registo de dados. Quando submete os seus dados, caso
            pretenda receber novidades, campanhas ou outros associados aos nossos serviços pedimos que concorde com o envio da mesma.
            <br />O fornecimento de dados pessoais
            apenas é necessário no caso de pretender efetuar um pedido de orçamento, um pedido de informações ou pedido de contacto e será sempre garantido, nos termos da
            lei, o direito de acesso, retificação e anulação de qualquer dado fornecido, podendo aquele direito ser exercido por escrito, a qualquer momento, através do
            e-mail lardarte@lardarte.pt ou clicando no link de cessação da subscrição, na parte inferior do e-mail.</p>
          <br />
          <p><b>Registo de dados</b></p><br /><p>Recolhemos informação pessoal, via o preenchimento voluntário dos devidos formulários dispostos para pedidos de
            orçamento, pedidos de informações ou serviços e pedidos de contacto.Ao contactar-nos através de e-mail, subscrever informações grátis, obriga-nos à
            recolha do seu endereço de e-mail, nome e outros dados específicos de identificação pessoal, traduzidos normalmente pelo nome e número de contacto.
            <br />
            Ao pedir dados pessoais sensíveis, assegurar-nos-emos que a sua recolha e utilização será feita no rigoroso cumprimento da legislação aplicável
            à recolha e proteção de dados. Se pretendermos utilizar os seus dados pessoais para além dos fins estabelecidos inicialmente, pediremos o seu
            consentimento. Poderá escolher antecipadamente dar esse consentimento aquando da recolha inicial de dados.</p><br /><p><b>Dados recolhidos
              automaticamente</b></p>
          <br />
          <p>Os domínios e endereços de IP dos visitantes são registados automaticamente. Esta informação não identifica o utilizador, mas apenas o
            computador utilizado no acesso ao website. Estes dados são analisados a nível global para verificar em que local do mundo é que o site está
            a ser utilizado, de forma a assegurar a sua cobertura, permitindo que o nosso serviço ao utilizador possa ser melhorado. Os dados pessoais
            de cada utilizador não são objeto de recolha e análise neste processo.</p><br /><p><b>Retenção de dados</b></p><br /><p>Os dados serão mantidos
              enquanto a subscrição do utilizador estiver ativa, com o objetivo de melhorar os serviços oferecidos, pelo que após a sua caducidade serão
              despersonalizados e não identificáveis. Apenas serão recolhidas informações adicionais acerca do utilizador para além das mencionadas acima
              caso surja necessidade de se obter mais informações para a Orçamentação do projeto.</p><br /><p><b>Acesso aos dados pessoais</b></p>
          <br />
          <p>Para solicitar esclarecimentos acerca do acesso aos dados pessoais, ou para o esclarecimento de questões relacionadas com a política de
            privacidade de dados, praticada na Lar Darte, contacte-nos através do e-mail lardarte@lardarte.pt, ou utilize o seguinte endereço postal:
            Zona Industrial da Ouvida, Lote 5, 3600-476 Castro Daire. Fornecer-lhe-emos uma cópia dos dados pessoais que dispomos sobre si num prazo
            máximo de 40 dias, a contar do seu pedido feito para este site; para este fim, ser-lhe-á exigida uma prova de identificação. É-lhe permitido
            questionar os dados e, onde apropriado, poderá: apagar os dados, retificá-los, emendá-los ou completá-los. Reservamo-nos ao direito de recusa
            de fornecimento da cópia dos dados pessoais do utilizador, obrigando-nos a apresentar uma justificação para essa decisão. O utilizador poderá
            contestar a nossa decisão de recusa de fornecimento de cópia com os seus dados pessoais.</p><br /><p><b>Revelação e escolha do visitante</b></p>
          <br />
          <p>O utilizador poderá cessar a subscrição do seu registo a qualquer momento, sendo este um processo simples. Ao disponibilizar os seus dados pessoais
            no início, fornecemos-lhe os procedimentos para o seu cancelamento, quer através de um link, quer através da disponibilização de um endereço de e-mail
            lardarte@lardate.pt. Ao disponibilizarmos os dados pessoais do utilizador para propósitos diferentes dos indicados, oferecemos a oportunidade ao
            utilizador de dar a sua autorização prévia.</p><br /><p><b>Segurança</b></p><br /><p>Foram implementadas políticas de segurança, regras e medidas
              técnicas para proteger os dados pessoais sob o nosso controlo, que estão inteiramente de acordo com a legislação de proteção de dados, apropriada
              à jurisdição aplicável ao website. As medidas de segurança estão desenhadas para prevenir acessos não autorizados, utilizações impróprias, modificações
              não autorizadas e destruições ou perdas acidentais ilícitas</p><br /><p><b>Cumprimento/conformidade de privacidade</b></p>
          <br /><p>A nossa política de
            privacidade está de acordo com a legislação portuguesa aplicável, nomeadamente a Lei n.o 67/98, de 26/10. A legislação de proteção de dados está
            sujeita a revisão, pelo que recomendamos ao utilizador a consulta regular da nossa declaração de privacidade. A título meramente indicativo, poderá
            para este efeito consultar o website lardarte.pt. Este website encontra-se em conformidade com a legislação nacional e comunitária. Os tribunais
            portugueses terão jurisdição exclusiva sobre qualquer disputa proveniente da utilização deste website.</p>
          <br />
          <p><b>Hyperlinks</b></p><br /><p>O website da Lar Darte poderá fornecer links para websites externos para sua conveniência e informação.
            Ao aceder a esses links sairá do website da Lar Darte , pelo que, a Lar Darte não controla esses websites nem as suas políticas de privacidade,
            que poderão ser diferentes das da Lar Darte;</p><br /><p>Não aprova nem representa estes sites.</p><br /><p>A Declaração de Privacidade da Lar
              Darte não cobre os dados pessoais eventualmente cedidos pelo utilizador para sites externos à Lar Darte. Recomendamos-lhe que reveja as políticas
              de privacidade de qualquer companhia antes de submeter a sua informação pessoal. Algumas companhias externas poderão escolher partilhar a sua
              informação pessoal com a Lar Darte, pelo que esta partilha será regida pela política de privacidade dessa companhia</p>
        </Styled>
      </Layout>
    </div>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`

const Styled = styled.div`
    max-width:1920px;
    margin:0 auto;
    padding: clamp(30px,10vw,60px) 5% clamp(30px,15vw,60px) 5%;
`